<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Inventarios
            </div>

            <v-row>
                <v-col>
                    <v-btn
                    @click="onClickNewInventario"
                    color="primary"
                    :loading="loading"
                    class="mt-5 mb-3">
                    Nuevo
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col cols="12" sm="4" md="5" lg="4" xl="3">
                    <ZonaFind class="mb-3" ref="zonaCtrl" v-model="filtros.Zona" label="Zona"></ZonaFind>
                </v-col>
                <v-col cols="12" sm="8" md="7" lg="8" xl="9">
                    <v-btn
                    @click="onBtnEdit(1)"
                    color="primary"
                    :disabled="selectedItem == null || filtros.Zona == null"
                    class="mb-3 mr-2">
                    Añadir con TPV
                    </v-btn>
                    <v-btn
                    @click="onBtnEdit(0)"
                    color="primary"
                    :disabled="selectedItem == null || filtros.Zona == null"
                    class="mb-3 mr-2">
                    Añadir con Tablet
                    </v-btn>
                    <v-btn
                    @click="onClickBorrarInventario"
                    color="error"
                    :loading="loading"
                    :disabled="selectedItem == null"
                    class="mb-3">
                    Borrar
                    </v-btn>
                </v-col>
            </v-row>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :item-class="selectedRow"
                :options.sync="options"
                :footer-props="{ itemsPerPageOptions: [15, 30, 50, 100], disablePagination:loading, disableItemsPerPage: loading }"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onClickRow"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1 inventarios-table"
                dense
                >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.FechaCreacion="{ item }">
                    {{ formatDate(item.FechaCreacion) }}
                </template>
            </v-data-table>
        </v-container>

        <!-- Modales de borrado -->
        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR INVENTARIO
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar el inventario? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :loading="loading"
                    color="primary"
                    @click="onBtnDelete">
                    Aceptar
                </v-btn>
                <v-btn @click="showDeleteDialog = false">
                    Cancelar
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import funcs from "../funcs";
import ZonaFind from "../components/ZonaFind.vue";
export default ({
    computed: {
        ...mapState(['empId', 'perId', 'perIdAux', 'urlRaiz', 'esTienda', 'esEmpleado'])
    },
    components: {
      ZonaFind
    },
    data () {
        return {
            loading: true,
            showDeleteDialog: false,
            selectedItem: null,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Fecha', width: 50, sortable: false, value: 'FechaCreacion' },
                { text: 'Tienda', width:300, sortable: false, value: 'Dir.DirNom' },
                { text: 'Creado por', width:300, sortable: false, value: 'Per.PerNom' }
            ],
            dtOptionsLoaded: false,
            filtros: {
                Dir: null,
                PerId: null,
                Zona: null
            }
        }
    },
    watch: {
      options: {
        handler () {
          // Evitamos la llamada a la API en la primera carga de las options
          if(!this.dtOptionsLoaded){
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      },
      // filtros: {
      //   handler () {
      //     this.loadData();
      //   },
      //   deep: true
      // },
    },
    mounted() {
        if (this.esTienda) {
            this.filtros.Dir = this.$store.state.dir;
        }

        // Per init.
        if (this.esEmpleado)
        {
            this.filtros.PerId = this.perId;
        }
        else if(this.perIdAux != null && this.perIdAux != 0)
        {
            this.filtros.PerId = this.perIdAux;
        }

        this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            DirId: this.filtros.Dir != null ? this.filtros.Dir.DirId : null
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/inventario/list", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  items,
                  total,
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al cargar los inventarios");
            }
          });
        })
      },
      postNewDataToApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            PerId: this.filtros.PerId,
            DirId: this.filtros.Dir != null ? this.filtros.Dir.DirId : null
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/inventario/new", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;
            // const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            this.loading = false;
            if(error != null) {
                alert("Error al crear el inventario");
            }
          });
        })
      },
      deleteDataFromApi(item) {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            InventarioId: item.InventarioId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/inventario/delete", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            this.loading = false;
            if(error != null) {
                alert("Error al borrar el inventario");
            }
          });
        })
      },
      loadData(){
        this.selectedItem = null;
        this.getDataFromApi()
          .then(data => {
            this.dataRows = data.items;
            this.totalDataRows = data.total;
          });
      },
      onClickNewInventario(){
          if (this.loading) return;
          this.postNewDataToApi()
          .then(data => {
              if(data.item != null)
              {
                  this.loadData();
              }
          });
      },
      onClickBorrarInventario(){
          this.showDeleteDialog = true;
      },
      onClickRow(item) {
          this.selectedItem = item;
      },
      onBtnDelete()
      {
          if (this.loading || this.selectedItem == null) return;
          this.deleteDataFromApi(this.selectedItem)
          .then(data => {
              if(data.item != null)
              {
                  this.showDeleteDialog = false;
                  this.loadData();
              }
          });
      },
      onBtnEdit(isTpv){
        if (this.selectedItem == null) return;
        this.$router.push('/inventario-editar/' + this.selectedItem.InventarioId + '/' + this.filtros.Zona.ZonaId + '/' + isTpv);
      },
      selectedRow(item) {
          if(this.selectedItem == null) { return ''; }
          if(item.InventarioId === this.selectedItem.InventarioId) {
              return 'selected-row';
          }
          return '';
      },
      formatDate(value)
      {
          return funcs.formatDate(value, true);
      }
    } 
})
</script>

<style>
  .inventarios-table .selected-row {
      /* border: 4px solid #FF4848; */
      background-color: #AECAFF;
  }
  .inventarios-table .selected-row:hover {
      background-color: #A3C3FF !important;
  }
</style>